<template>
  <div>
    <div class="staff">
      <div class="left-top">
        <span style="font-size: 18px; font-weight: 400; color: #333">客户案例管理</span>
        <el-divider></el-divider>
      </div>
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-width="50px" label-position="left" style="display: flex">
        <el-form-item label="产品:" prop="Product">
          <el-select v-model="searchForm.Product" style="width: 150px;">
            <el-option v-for="item in productList" :key="item.Key" :value="item.Key" :label="item.Value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业:" prop="Industry">
           <el-cascader
            style="width:150px;"
            v-model="searchForm.Industry"
            :options="industryList"
            :props="industryListObj"
            placeholder="全部"
            :show-all-levels="false"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="规模:" prop="Scale">
          <el-select v-model="searchForm.Scale" style="width: 150px">
            <el-option v-for="item in scaleList" :key="item.Key" :value="item.Key" :label="item.Value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示:" label-width="100px">
          <el-select v-model="searchForm.IsShow" style="width: 90px">
            <el-option v-for="item in isShowList" :key="item.value" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item id="addClassRight">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
          <el-button type="primary" @click="addPage">新增客户案例</el-button>
        </el-form-item>
      </el-form>
      <el-table border :data="PageConfigure || []" row-key="CategoryGuid" style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
        <el-table-column label="案例标题" width="150" prop="Title" align="center">
        </el-table-column>
        <el-table-column label="是否显示" width="120" prop="IsShow" align="center">
          <template slot-scope="scope">
            <el-switch @change="changeswitch(scope.row)" v-model="scope.row.IsShow" active-color="#13ce66"
              :active-value="1" :inactive-value="0" inactive-color="#ff4949">
            </el-switch>
            <span class="switchvalue" v-show="scope.row.IsShow == 1">开启</span>
            <span class="switchvalue close" v-show="scope.row.IsShow == 0">关闭</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" width="200" prop="CreateTime" sortable>
          <template slot-scope="scope">
            <span>{{ scope.row.CreateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="公司名称" width="150" prop="CompanyName" align="center">
        </el-table-column>
        <el-table-column label="行业" width="150" prop="IndustryName" align="center">
          <template slot-scope="scope">
            {{scope.row.IndustryName?scope.row.IndustryName:'--'}}
          </template>
        </el-table-column>
        <el-table-column label="产品" width="250" prop="ProductNameList" align="center">
          <template slot-scope="scope">
            {{scope.row.ProductNameList?scope.row.ProductNameList.join("/"):'--'}}
          </template>
        </el-table-column>
        <el-table-column label="规模" width="150" prop="ScaleName" align="center">
           <template slot-scope="scope">
            {{scope.row.ScaleName?scope.row.ScaleName:'--'}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="300" label="操作" align="center">
          <template slot-scope="scope">
            <span class="backSpan" @click="eduit(scope.row, 'seek')" style="color: #409eff">
              查看
            </span>
            <span class="backSpan" @click="eduit(scope.row, 'edit')" style="color: #409eff">
              编辑
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="task-list-page" v-if="PageConfigure !== ''">
        <el-pagination @current-change="OnCurrentPageChanged" @size-change="handleSizeChange"
          :current-page="Params.pageIndex" :page-sizes="[10, 20, 30, 40, 50, 60]"
          layout="prev, pager, next,sizes,jumper,total" :page-size="Params.pageSize" background
          :total="Params.TotalCount">
        </el-pagination>
      </div>

      <!-- 编辑 -->
      <div class="formdia">
        <el-dialog :modal-append-to-body="false" :visible.sync="dialogFormVisible" custom-class="edit-form"
          :title="dialongtitle" @open="openDialog()" width="640px">
          <el-form :model="Rowcontent" :rules="rules" ref="editForm" :disabled="!isEditable">
            <el-form-item label="案例标题:" :label-width="formLabelWidth" prop="Title">
               <el-input style="width: 500px;" v-model="Rowcontent.Title" placeholder="请输入案例标题"
                maxlength="30" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="案例图片:" :label-width="formLabelWidth" prop="ImageURL">
              <el-upload style="width: 500px;" class="avatar-uploader" action="" :show-file-list="false"
                :http-request="(data) => uploadFile(data.file)">
                <img v-if="isImageUrl" :src="Rowcontent.ImageURL" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="是否显示:" label-width="100px" prop="IsShow">
              <el-select v-model="Rowcontent.IsShow" style="width: 200px">
                <el-option v-for="item in isShowListDia" :key="item.value" :value="item.value"
                  :label="item.label"></el-option>
              </el-select>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="公司名称:" :label-width="formLabelWidth" prop="CompanyName">
                  <el-input style="width: 200px" v-model="Rowcontent.CompanyName" placeholder="请输入公司名称"
                    id="companyCenter" maxlength="15" show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="行业:" :label-width="formLabelWidth" prop="Industry">
                    <el-cascader
                      style="width:200px;"
                      v-model="Rowcontent.Industry"
                      :options="industryList"
                      :props="industryListObj"
                      placeholder="全部"
                      @change="selectIndustry">
                    </el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="产品:" :label-width="formLabelWidth" prop="Product">
                  <el-select v-model="Rowcontent.Product" style="width: 200px;" :multiple-limit="3" multiple clearable @change="slectProduct" >
                    <el-option v-for="item in productList" :key="item.Key" :value="item.Key" :label="item.Value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="规模:" :label-width="formLabelWidth" prop="Scale">
                  <el-select v-model="Rowcontent.Scale" style="width: 200px" clearable>
                    <el-option v-for="item in scaleList" :key="item.Key" :value="item.Key"
                      :label="item.Value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="案例简介:" :label-width="formLabelWidth" prop="CaseIntroduction">
              <el-input type="textarea" placeholder="请输入案例简介" v-model="Rowcontent.CaseIntroduction" maxlength="88"
                show-word-limit :autosize="{ minRows: 3, maxRows: 3 }" style="width: 500px">
              </el-input>
            </el-form-item>
            <el-form-item label="链接案例文章:" :label-width="formLabelWidth" prop="CaseUrl" style="margin-left: -10px;">
              <el-input style="width: 500px" v-model="Rowcontent.CaseUrl" placeholder="请输入链接案例文章"
                maxlength="40"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer" v-if="isFooterVisible">
            <el-button @click="dialogFormVisible = false">返回</el-button>
            <el-button :disabled="isDisabled" @click="submitForm('editForm')">
              确定
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //对话框脚部显隐
      isFooterVisible: true,
      isEditable:true,
      //图片显隐
      isImageUrl: false,
      //查询表单集
      searchForm: {
        Product: "",
        Industry: "",
        Scale: "",
        IsShow: "",
      },
      //产品下拉选项
      productList: [],
      industryListObj: {
        value: "Key",
        label: "Value",
        children: "Children",
        checkStrictly: false
      },
      // 行业下拉选项
      industryList: [],
      //规模下拉选项
      scaleList: [],
      //是否显示下拉选项
      isShowList: [
        { value: "", label: "全部" },
        { value: 1, label: "开启" },
        { value: 0, label: "隐藏" },
      ],
      isShowListDia: [
        { value: 1, label: "开启" },
        { value: 0, label: "隐藏" },
      ],
      rules: {
        CompanyName: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
        Industry: [
          { required: true, message: "请完善信息", trigger: "change" },
        ],
        Product: [
          { required: true, message: "请完善信息", trigger: "change" },
        ],
        
        ProductName: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],

        Scale: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],

        CaseIntroduction: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],

        CaseUrl: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],

        IsShow: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],

        ImageURL: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],

        Title: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
      },

      dialogFormVisible: false,
      dialongtitle: "",
      formLabelWidth: "120px",
      Rowcontent: {
        Id: "",
        Title: "",
        IsShow: 1,
        CompanyName: "",
        Industry:null,
        IndustryName: "",
        Product:null,
        ProductNameList: null,
        Scale: "",
        ScaleName: "",
        CaseIntroduction: "",
        CaseUrl: "",
        ImageURL: "",
      },

      PageConfigure: [], //列表分页数据
      Params: {
        pageIndex: 1,
        pageSize: 10,
        TotalCount: 5,
        TotalPage: 0,
      },
      IsEdit: false,
      twoAlltype: [
        { label: "http", value: "http" },
        { label: "https", value: "https" },
      ], //获取所有分类
      value: "http",
      imgshow: "1",
      //解决重复点击问题
      isDisabled: false,
    };
  },
  created() {
    this.getSelectItems();
  },
  mounted() {
    this.searchDate();
  },
  methods: {
    //获取产品、行业、规模下拉选项数据
    getSelectItems() {
      this.$get(this.$api.GetSelectItems).then(res => {
        this.productList = res.ProductAlternative;
        this.scaleList = res.ScaleAlternative;
        let industry = res.IndustryAlternative;
        const industryData = industry
        // 过滤出一级分类（ParentCode 为 null）
        .filter(item => item.ParentCode === null)
        .map(item => {
          // 为一级分类创建一个对象，包含其自身的信息和一个 children 数组
          const category = {
            ...item,
            Children: []
          };
          // 查找所有子分类并添加到当前一级分类的 children 数组中
          const children = industry.filter(child => child.ParentCode === item.Key);
          category.Children = children.map(child => ({
            ...child,
            // 可以根据需要添加或删除子分类的属性
          }));          
          return category;
        });
        this.industryList = industryData;
        //console.log(this.industryList)
      })
    },
    

    //查询分页结果
    search() {
      let _this = this;
      let industry = this.searchForm.Industry;
      if(industry.length>1){
        this.searchForm.Industry = industry[industry.length - 1];
      }
      this.$post(this.$api.SearchCustomerCases, {
        ..._this.searchForm, ..._this.Params
      }).then((res) => {
        _this.PageConfigure = res.Data;
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
      });
    },
    //上传案例图片
    uploadFile(file) {
      let _this = this;
      let nameIndex = file.name.lastIndexOf('.');
      let type = file.name.substring(nameIndex + 1, file.name.length);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return this.$message.error('上传图片文件大小不能超过 2MB!');
      }
      var formData = new FormData();
      formData.append("file", file);

      this.$post(this.$api.UploadFileV2 + '?module=adsense&fileExpand=.' + type, formData).then(res => {
        if (res.code == 200) {
          _this.Rowcontent.ImageURL = res.data;
          console.log(_this.Rowcontent.ImageURL);
          _this.isImageUrl = true;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    //重置查询
    resetForm(searchForm) {
      this.$refs[searchForm].resetFields();
      this.searchForm.Industry=[],
      this.searchForm.IsShow="",
      this.Params.pageIndex=1;
      this.searchDate();
    },
    //获取数据
    searchDate() {
      var _this = this;
      this.$post(_this.$api.SearchCustomerCases, _this.Params)
        .then((res) => {
          _this.PageConfigure = res.Data;
          console.log(this.PageConfigure);
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //是否开启
    changeswitch(row) {
      this.$post(this.$api.EditCustomerCase, row)
        .then((res) => {
          if (res.IsSuccess) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: "800",
            });
          }else{
            this.$message.error(res.Message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //打开dialog弹框时清除自动校验
    openDialog(){
      this.$nextTick(()=>{
        this.$refs.editForm.clearValidate();
      })
    },
    
    // 新增&编辑 选择行业
    selectIndustry(val){
      let industry = val[val.length-1];
      if(industry){
        this.$set(this.Rowcontent,"Industry",industry);
      }
    },
    slectProduct(val){
      console.log(val)
      this.Rowcontent.Product = val;
    },
    //新增
    addPage() {
      this.IsEdit = false;
      this.dialongtitle = "新增客户案例";
      this.dialogFormVisible = true;
      this.isFooterVisible = true;
      for (let key in this.Rowcontent) {
        if (this.Rowcontent.hasOwnProperty(key)) {
          this.Rowcontent[key] = '';
        }
      }
    },

    //编辑
    eduit(val, type) {
      this.IsEdit = true;
      if (type == 'seek') {
        this.dialongtitle = "客户案例";
        this.isFooterVisible = false;
        this.isEditable = false;
      } else {
        this.dialongtitle = "编辑客户案例";
        this.isFooterVisible = true;
        this.isEditable = true;
      }
      this.dialogFormVisible = true;
      this.Rowcontent = val;
      //console.log(val.Product)
      if(val.Product&&val.Product.length){
        let product = val.Product.split(';');
        this.Rowcontent.Product = product;
      }
      console.log(this.industryList,val.Industry)
      this.isImageUrl = true;
    },
    //新增、编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },
    //新增、编辑的提交
    submitForm(editForm) {
      let _this = this;
      _this.isDisabled = true;
      _this.entime();
      _this.$refs[editForm].validate((valid) => {
        if (valid) {
          this.Rowcontent.Product=this.Rowcontent.Product.join(";")
          if (_this.IsEdit) {
            console.log("编辑");
            console.log(_this.Rowcontent);
            this.$post(this.$api.EditCustomerCase, _this.Rowcontent)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "编辑成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                  _this.dialogFormVisible = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            console.log("新增");
            console.log(_this.Rowcontent);
            this.$post(this.$api.AddCustomerCase, _this.Rowcontent)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "新增成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                  _this.dialogFormVisible = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });           
          }
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
          });
        }
      });
    },

    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.pageIndex = val;
      console.log(val);
      this.search();
    },

    //改变页码
    handleSizeChange(val) {
      this.Params.pageSize = val;
      console.log(val);
      this.search();
    },

    //删除
    PreDelet(row, index, data) {
      this.$confirm("确认删除？", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        iconClass: "el-icon-question",
      })
        .then(() => {
          this.$post(this.$api.DeleteLink + "?id=" + row.Id)
            .then((res) => {
              if (res.IsSuccess) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.searchDate();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
  /deep/.is-center.is-leaf {
    background: #e6e6e6;
    color: #303133;
  }
  
  /deep/.has-gutter tr {
    th {
      background: #e6e6e6;
      color: #303133;
    }
  
    td {
      text-align: center;
    }
  }
  
  // /deep/ .el-form:first-child .el-form-item:nth-child(4) {
  //   margin-left: 100px;
  // }

  /deep/ .formdia .el-form .el-form-item {
    display: flex;
    justify-content: flex-start;
    .el-form-item__content{
      margin-left: 0px;
    }
  }

  // /deep/ .formdia .el-form .el-form-item:nth-child(2) {
  //   display: flex;
  //   justify-content: center;
  //   padding-left: 0px;
  // }

  /deep/ .formdia .el-form .el-form-item:nth-child(3) {
    display: flex;
    justify-content: flex-start;
  }

  /deep/ .formdia .el-form .el-form-item:nth-child(6) {
    display: flex;
    justify-content: flex-start;
    margin-left: 0px;
  }

  /deep/ .formdia .el-form .el-form-item .el-form-item__content {
    margin-left: 0px !important;
  }

  /deep/ #companyCenter {
    padding-right:45px;
  }

  #addClassRight{
    margin-left: 100px;
    margin-right: 0px;
  }

  /deep/ #addClassRight .el-form-item__content {
    width: 100%;
    position: relative;
  }

  /deep/ #addClassRight .el-form-item__content button:nth-child(3){
    float: right;
  }
  .avatar-uploader .el-upload {
    width: 320px;
    height: 140px;
    border: 1px dashed black;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 320px;
    height: 140px;
    line-height: 140px;
    text-align: center;
    border: 1px dashed black;
  }
  .avatar {
    width: 320px;
    height: 140px;
    display: block;
    background-size: cover;
  }

  .staff {
    background: #fff;
    padding: 10px 10px;
    border-radius: 10px;
  
    .backSpan {
      padding: 0px 20px;
      cursor: pointer;
    }
  
    .left-top {
      margin-bottom: 10px;
  
      .el-divider--horizontal {
        margin: 10px 0;
      }
  
      button {
        margin-left: 10px;
      }
      .el-form .el-form-item{
        display: flex;
        justify-content: center;
      }
    }
  
    .el-table .cell span {
      overflow: hidden; /* 超出部分隐藏 */
      white-space: nowrap; /* 不换行 */
      text-overflow: ellipsis; /* 超出部分文字以...显示 */
    }
  
    .task-list-page {
      margin-top: 10px;
    }
  }
  
  .switchvalue {
    font-size: 13px;
    color: #13ce66;
    padding-left: 5px;
    -moz-user-select: none; /* 火狐 */
    -webkit-user-select: none; /* webkit浏览器 */
    -ms-user-select: none; /* IE10 */
    -khtml-user-select: none; /* 早期浏览器 */
    -o-user-select: none; /* Opera */
    user-select: none;
  }
  
  .switchvalue.close {
    color: #ff4949;
  }
  
  /* 编辑框样式 */
  /deep/.edit-form {
    width: 500px;
  
    .el-dialog__header {
      height: 50px;
      text-align: center;
      background-color: #409eff;
  
      .el-dialog__title {
        line-height: 0px;
        font-size: 22px;
        color: #fff;
      }
  
      .el-dialog__headerbtn {
        display: none;
      }
    }
  
    .el-form-item .el-textarea__inner {
      resize: none;
    }
  
    .el-dialog__body {
      padding-bottom: 5px !important;
    }
  
    .dialog-footer {
      text-align: center;
  
      .el-button {
        background-color: #fff;
        color: #606266;
        border: 1px solid #dcdfe6;
      }
  
      .el-button:hover {
        background-color: #409eff;
        border-color: #409eff;
        color: #fff;
      }
    }
  
    img.upImg {
      width: 40px;
      height: 40px;
      background-size: 100% 100%;
      float: left;
    }
  }
  
  // 小星星
  .xiaoxx {
    color: #F56C6C;
    position: absolute;
    top: 0;
    left: -10px;
  }
  </style>